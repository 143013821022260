const  ServicesData = [
    [
      "Statutory Audit of Companies.",
      "Tax Audit under Section 44AB of the Income Tax Act, 1961.",
      "Audit under other sections of the Income Tax Act, 1961 such as 80HHC, 80-IA, 80IB, etc.",
      "Audit under GST Act",
      "Audit of Charitable Trusts, Schools, etc.",
      "Concurrent Audits.",
      "Internal Audits.",
      "Revenue Audit of Banks.",
      "Branch Audits of Banks."
    ],
    [
      "Consultancy on various intricate matters pertaining to Income tax.",
      "Filing Income Tax returns for all kinds of assesses.",
      "Liaison with Income tax department for rectification, assessment, obtaining refunds etc.",
      "Effective tax management, tax structuring and advisory services.",
      "Tax Planning for corporate and others clients.",
      "Filing and pleading appeals under various provisions of IT Act.",
      "Expertise in complicated direct tax assessments.",
      "Designing / restructuring salary structure to minimize tax burden.",
      "Obtaining PAN for assesses, employees etc.",
      "Advance tax estimation and deposit.",
      "Providing regular updates on amendments, circulars, notifications & judgments."
   ],
    [
      "Registration and amendment under Goods and Service tax Act.",
      "Preparation and filing of GST Returns.",
      "Consultancy for reply to notices and assessment under GST Act.",
      "Reconciliation of GST credit with GSTN portal.",
      "Consultancy for maintenance of proper records.",
      "Consultancy for proper accounting for GST.",
      "Consultancy on various issues relating to GST.",
      "Assistance in availment of notified exemptions, abatements etc.",
      "Utilization and availment of GST credit in accordance with the provisions of GST Act."
   ],
    [
      "Advice on all matters related to compliance of TDS/TCS provisions.",
      "Obtaining Tax Deduction Account Number (TAN).",
      "Periodic review of TDS compliance.",
      "Computation of monthly TDS.",
      "Monthly reconciliation of TDS due and deposited.",
      "Monthly deposit of TDS electronically.",
      "Issue of quarterly TDS certificates.",
      "Filing of quarterly E-TDS Returns.",
      "Filing of correction statements."
   ],
    [
      "Incorporation of company.",
      "Consultancy on Company Law matters.",
      "Filing of annual returns and various forms, documents.",
      "Secretarial Matters including share transfers.",
      "Maintenance of Statutory records.",
      "Change of Name, Objects, Registered Office, etc."
   ],
    [
      "Accounting System Design & Implementation.",
      "Financial Accounting.",
      "Budgeting.",
      "Financial Reporting.",
      "MIS Reports.",
      "Financial Analysis.",
      "Asset Accounting Management.",
      "Depreciation and Amortization Schedules."
   ],
    [
      "Preparations of Project Reports.",
      "Preparation of CMA data for bank loans."
      
    ]
];

export default ServicesData;