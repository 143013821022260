import React from 'react'; 
import {useEffect} from 'react';
import ServicesData from "./ServicesData";
import { useParams } from "react-router-dom";
import "./styles/serviceslist.css";
import "./styles/about.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHome } from '@fortawesome/fontawesome-free-solid'

export const ServicesList = () => {
    const { Main,Id } = useParams();
useEffect(() => {
  window.scrollTo(0, 0);
}, [])

    return (
        <>
        <div className="d-flex justify-content-center">
            <div className="col-lg-8 offset-lg-0 col-md-8 offset-md-1">
                <div style={{ padding: "1% 5% 1% 5%" }}>
                    <h1 className='mainTitle'>{Main}</h1>
                    <br />
                    <ul style={{listStyle:"none"}}>
                        {ServicesData[Id].map((item) => 
                        { return <>
                        <div className='item-div'>
                        <li className='item'>{item}</li> 
                        </div>
                        </>
                        })}
                    </ul>
                </div>
            </div>
            <br />
        </div>

        <a href="/" className="back">
            <FontAwesomeIcon icon={faHome} />
        </a>
        </>
    )
}
