import React from 'react'
import "./styles/services.css";
import "./styles/about.css";
import { NavLink } from 'react-router-dom';

import Audit from "../images/services/audit.jpeg";
import TDS from "../images/services/TDS.jpeg";
import Tax from "../images/services/tax.jpeg";
import GST from "../images/services/gst.jpeg";
import Management from "../images/services/management-services.jpeg";
import Corporate from "../images/services/corporate-finance.jpeg";
import Accounting from "../images/services/accounting-services.jpeg";
import Many from "../images/services/many-more.jpeg";
export const Services = () => {
  return (
    <>
      <div className="services" id="services">
        <div className="container">
          <h1 className='mainTitle' style={{fontWeight:"600"}}>Available Services</h1>
          <div className='services-list'>
            <NavLink className='services-sub-title' to="services/Audit/0">
              <div className='services-div'>
                <img className="logo-img" src={Audit} />
                <h2 className='services-name'>Audit</h2>
              </div>
            </NavLink>
            <NavLink className='services-sub-title' to="services/Income-tax/1">
              <div className='services-div'>
                <img className="logo-img" src={Tax} />
                <h2 className='services-name'>Income-tax</h2>
              </div>
            </NavLink>
            <NavLink className='services-sub-title' to="services/Goods and Service-tax/2">
              <div className='services-div'>
                <img className="logo-img" src={GST} />
                <h2 className='services-name'>Goods and Service-tax &#40;GST&#41;</h2>
              </div>
            </NavLink>
            <NavLink className='services-sub-title' to="services/Tax Deducted at Source (T.D.S)/3">
              <div className='services-div'>
                <img className="logo-img" src={TDS} />
                <h2 className='services-name'>Tax Deducted at Source &#40;T.D.S&#41;</h2>
              </div>
            </NavLink>
            <NavLink className='services-sub-title' to="services/Corporate Services/4">
              <div className='services-div'>
                <img className="logo-img" src={Corporate} />
                <h2 className='services-name'>Corporate Services</h2>
              </div>
            </NavLink>
            <NavLink className='services-sub-title' to="services/Accounting Services/5">
              <div className='services-div'>
                <img className="logo-img" src={Accounting} />
                <h2 className='services-name'>Accounting Services</h2>
              </div>
            </NavLink>
            <NavLink className='services-sub-title' to="services/Project Finance/6">
              <div className='services-div'>
                <img className="logo-img" src={Management} />
                <h2 className='services-name'>Project Finance</h2>
              </div>
            </NavLink>
            <NavLink className='services-sub-title' to="#">
              <div className='services-div'>
                <img className="logo-img" src={Many} />
                <h2 className='services-name'>and Many More</h2>
              </div>
            </NavLink>

          </div>


        </div>


      </div>

    </>
  )
}
