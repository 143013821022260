import React, { useState, useEffect } from 'react';
import "./styles/footer.css";
import QR from "../images/QR-code.png";
export const Footer = () => {
  const [date, setDate] = useState();

  const getYear = () => setDate(new Date().getFullYear())


  useEffect(() => {
    getYear();
  }, [])
  return (
    <>
      {/* <div className='w-100 text-center footer'>
    </div> */}
      <footer className="footer">
        <div className="container bottom_border">
          <div className="row">
            <div className=" col-sm-4 col-md col-sm-4  col-12 col">
              <h5 className="headin5_amrc col_white_amrc pt2">Find Us</h5>
              <p className="mb10 detail">412-413 Suvas Scala,<br /> Opp. Nikol Police Station, <br/>Gangotri Circle-Ring Road, <br/>Nikol, Ahmedabad - 380049</p>

              <h5 className="headin5_amrc col_white_amrc pt1"><i className="fa fa-location-at"></i>Locate Us</h5>
              <p className='detail'><a href="https://maps.app.goo.gl/TdmK3bi2kp9iFAsc6" target="_blank">via Google Maps</a> </p>

            </div>


            <div className=" col-sm-4 col-md  col-6 col">
              <h5 className="headin5_amrc col_white_amrc pt2">Quick links</h5>
              <ul className="footer_ul_amrc">
                <li><a href="https://incometaxindia.gov.in/Pages/default.aspx" target="_blank">Income Tax</a></li>
                <li><a href="https://www.incometax.gov.in/iec/foportal" target="_blank">Income Tax E filling</a></li>
                <li><a href="https://www.gst.gov.in/" target="_blank">Goods and Services Tax</a></li>
                <li><a href="https://onlineservices.tin.egov-nsdl.com/etaxnew/tdsnontds.jsp" target="_blank">E Payment of Direct Taxes</a></li>
                <li><a href="https://contents.tdscpc.gov.in/" target="_blank">Traces</a></li>
                <li><a href="https://www.mca.gov.in/content/mca/global/en/home.html" target="_blank">Ministry of Corporate Affairs &#40;MCA&#41;</a></li>
                <li><a href="https://www.icai.org/" target="_blank">ICAI</a></li>
              </ul>
            </div>

            <div className=" col-sm-4 col-md  col-6 col">
              <div className='text-center'>
              <h5 className="headin5_amrc col_white_amrc pt2">Give us your <br/>Valuable Feedback</h5>
              <ul className="footer_ul_amrc">
                <li>
                  <a href="https://search.google.com/local/writereview?placeid=ChIJcyzyLpGGXjkRXfWXM6c0Sp8&rating=5" target="_blank">
                    <img src={QR} alt="" style={{width:"100px",height:"100px"}}/>
                    <br />
                  <h6 style={{paddingTop:"3%"}}>Scan or Click the code</h6></a></li>
              </ul>
              </div>
            </div>

            <div className=" col-sm-4 col-md  col-12 col">
              <h5 className="headin5_amrc col_white_amrc pt2" id="contact">Contact us</h5>
              <p className='detail'> Mobile: <a href="tel:+919377092292">+91 9377092292</a> </p>
              <p className='detail'> Landline: <a href="tel:07946007658">079 46007658 </a> </p>
              <br />
              <h5 className="headin5_amrc col_white_amrc pt1"><i className="fa fa-envelope"></i> Email us:</h5>
              <p className='detail'> <a href="mailto:slpatelco@gmail.com">slpatelco@gmail.com</a> </p>
              <p className='detail'><a href="mailto:sslpatelco@yahoo.com"> slpatelco@yahoo.com</a> </p>

            </div>
          </div>
        </div>


        <div className="container text-center">
          <p className='copy mb10'>Copyright &copy; {date} Reserved to the <strong>S. L. Patel &amp; Co.</strong> and <a href="https://www.linkedin.com/in/jainish31/" target="_blank" style={{textDecoration:"none"}}>Developer</a> of the site. </p>
        </div>

      </footer>
    </>
  )
}
