import React from 'react'
import "./styles/about.css";
import Logo from '../images/about-company.png';
export const About = () => {
  return (
    <>
      <div className='about-div' id="about">
        <h1 className='mainTitle' style={{fontWeight:"600"}}>About Us</h1>
        <hr />
              <div className='about-row-div row' >
                <div className='about-col-1'>
                  <img src={Logo} alt="Shailesh Patel" className='company' />
                </div>
                <div className='about-col-2'>
                  <br />
                  <p className='content'><strong>S. L. Patel &amp; Co.</strong> is a well known, professionally managed chartered accountancy firm. It was established in the year <strong>1999</strong>. It is a leading firm, located in Ahmedabad city of Gujarat, for more than <strong>21 years</strong>. The team consists of distinguished chartered accountants and company secretary, skilled taxation and audit staff, tax consultants, and other well-qualified staff. The firm has more than <strong>250 corporate and non-corporate clients</strong>. Our valued clients comprise of private limited companies, builders, governments contractors, exporters and service provides, having turnover ranging from <strong>2 crores to 100 crores</strong>. <br/> <br/>The firm represents a combination of specialized skills, which are geared to offer sound financial advice and personalized proactive services, which include tax audit, company audit, NGO audit, income-tax consultancy, GST consultancy, management consultancy, tax planning, corporate law related services, accounting services, and more. Those associated with the firm have regular interaction with industry and other professionals which enables the firm to keep pace with contemporary developments and to meet the needs of its clients.</p>
                </div>
              </div>
            </div>
    </>
  )
}
