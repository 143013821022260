import mem1 from "../images/team-members/shailesh.jpeg";
import mem2 from "../images/team-members/dhaval.jpg";
import mem3 from "../images/team-members/team-profile.jpeg";
import mem4 from "../images/team-members/heena.png";
import mem5 from "../images/team-members/jaimin.jpeg";

const TeamData = [
    {
        username: "Shailesh L. Patel",
        degree:"B.com, FCA, DISA",
        imgsrc: mem1,
    },
    {
        username: "Dhaval M. Shah",
        degree:"M.com, FCA, DISA",
        imgsrc: mem2,
    },
    {
        username: "Vishal Gosai",
        degree:"B.com, C.S",
        imgsrc: mem3,
    },
    {
        username: "Heena R. Rathod",
        degree:"M.com., LLB, Inter C.A.",
        imgsrc: mem4,
    },
    {
        username: "Jaimin H. Patel",
        degree:"Account & Tax Consultant",
        imgsrc: mem5,
    },
    

];
export default TeamData;
