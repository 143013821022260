import React from 'react'
import "./styles/contact.css";
import emailjs from 'emailjs-com';
export const Contact = () => {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_g5gc3oc', 'gmail-template', e.target, 'XuqlcZd5MXPEwvaMR')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
  };
  return (
    <>
      <div className='contact-div' >
        <div className="container">
          <div className="row">
            <h1 className='mainTitle' style={{fontWeight:"600"}}>Connect with Us</h1>
          </div>
          <div className="row">
            <h4 style={{ textAlign: "center" }}>We'd love to hear from you!</h4>
          </div>
          <form onSubmit={sendEmail}>
            <div className="row input-container">
              <div className="col-xs-12">
                <div className="styled-input wide">
                  <input type="text" required style={{ fontSize: "1rem" }} name="from_name" />
                  <label>Name</label>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="styled-input">
                  <input type="email" required name="email" />
                  <label>Email</label>
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="styled-input" style={{ float: "right" }}>
                  <input type="tel" required name="phone" />
                  <label>Phone Number</label>
                </div>
              </div>
              <div className="col-xs-12">
                <div className="styled-input wide">
                  <textarea required name="message"></textarea>
                  <label>Message</label>
                </div>
              </div>
              <div className="col-xs-12 text-center">
              <button type='submit' style={{border:"none", background:"transparent"}}><div className="btn-lrg submit-btn">Send Message</div></button>
              </div>
            </div>
          </form>
        </div>

      </div>
    </>
  )
}
