import React from 'react'
import "./styles/home.css";
export const Home = () => {
  return (
    <>
      <div className="home-div">
      <div className="row main-div" >
        <div className="sub-div">
          <h1 className="mainTitle" id="mainTitle" style={{color:"black",fontWeight:"600"} }>Welcome to</h1>
          <h1 className="title">S. L. Patel &amp; Co.</h1>
        </div>
      </div>
      <div>

      </div>
      </div>
    </>
  )
}
