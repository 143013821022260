import React from 'react';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { Switch, Route, Redirect } from 'react-router-dom';
import { Header } from './components/Header';
import { Home } from "./components/Home";
import { About } from "./components/About";
import { Services } from "./components/Services";
import { OurVision } from "./components/OurVision";
import { ServicesList } from "./components/ServicesList";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
function App() {
  return (
    <>
    <Header />
      <Switch>
        <Route exact path="/" render={() => {
            return (
              <>
                <Home/>
                <About />
                <Services />
                <Contact />
              </>
            )
          }} />
        <Route exact path="/services" component={Services} />
        <Route exact path="/our-vision" component={OurVision} />
        <Route exact path="/services/:Main/:Id" component={ServicesList} />
        <Redirect to="/" />
      </Switch>
      <Footer />
 
  </>
  );
}

export default App;
