import React from 'react'
import "./styles/ourvision.css";
import "./styles/about.css";
import Founder from "../images/founder.jpeg";
import Data from "./TeamData"
export const OurVision = () => {
  return (
    <>
      <div className="our-vision-div justify-content-center" id="ourVision">
        <div className='our-vision-div'>
          <h1 className='mainTitle' style={{ fontWeight: "550" }}>Founder's details</h1>
          <img className="founder" src={Founder} alt="" />
          <br />
          <p className='our-content'>Shaileshkumar L. Patel is a founder of  <strong>S. L. Patel &amp; Co.</strong> He is a Commerce Graduate from Gujarat University. He is also a qualified Chartered Accountant and Information System Auditor (DISA). He has experience of more than 21 years in the field of auditing, corporate law, direct and indirect taxes like VAT, Service-tax, GST etc. He also has expertise and experience in various areas like income-tax, VAT, service-tax and GST. I have trained more than 40 audit trainees including 8 Chartered Accountants and 6 Inter C.A.</p>
        </div>
        <br />
        <div className='our-ethics'>
          <h1 className='mainTitle' style={{ fontWeight: "550", textAlign: "left" }}>Our Thoughts and Ethics</h1>
          <ul >
            <li>Excellence in Professional Services</li>
            <li>Place the Interest of Clients before ours</li>
            <li>Continuous Education and Training of Staff and Clients</li>
            <li>Uphold Highest Standards of Honesty and Integrity</li>
            <li>Endeavour to Improve the Quality of Services</li>
            <li>Access to latest Information Technology Infrastructure and Continuous updates on the regulatory changes.</li>
          </ul>

        </div>

        <section className="section-team">
		<div className="container">
			<div className="row justify-content-center text-center">
				<div className="col-md-8 col-lg-6">
					<div className="header-section">
						<h2 className="mainTitle">Meet our team members</h2>
					</div>
				</div>
			</div>
			<div className="card-collection-div">
        {Data.map((item, index) => {  return <>
        	<div className="col-sm-6 col-lg-4 col-xl-3 single-person-div" key={index}>
					<div className="single-person">
						<div className="person-image">
							<img src={item.imgsrc} alt=""/>
						</div>
						<div className="person-info">
							<h3 className="full-name">{item.username} </h3>
							<span className="speciality"><b>Degree: </b>{item.degree}</span>
						</div>
					</div>
				</div>
        </> })}
				
			</div>
		</div>
	</section>
      </div >

    </>
  )
}
