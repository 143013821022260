import React from 'react';
import "./styles/header.css";
import Container from 'react-bootstrap/Container';
import {NavLink} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../images/logo.png';
import AnchorLink from "react-anchor-link-smooth-scroll";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

export const Header = () => {
  var sidemenu = document.getElementById("menu");
  function openmenu() {
    sidemenu.style.right = "0";
  }
  function closemenu() {
    sidemenu.style.right = "-200px";
  }

  return (
    <>
      <div className='conitainer'>
        <Navbar bg="light" expand="lg">
          <Container>
            <Navbar.Brand href="/"><img src={Logo} width="250" height="24" className="logo" alt="" /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto">
                <Nav.Link className="link" href="#about">About Us</Nav.Link>

                <NavDropdown className="link" title={
                  <span className="text-dark my-auto">Services</span>
                } id="basic-nav-dropdown">
                  <NavDropdown.Item href="services/Audit/0">Audit</NavDropdown.Item>
                  <NavDropdown.Item href="services/Income-tax/1">Income-tax</NavDropdown.Item>
                  <NavDropdown.Item href="services/Goods and Service-tax/2">Goods and Service-tax &#40;GST&#41;</NavDropdown.Item>
                  <NavDropdown.Item href="services/Tax Deducted at Source (T.D.S)/3">Tax Deducted at Source &#40;T.D.S&#41;</NavDropdown.Item>
                  <NavDropdown.Item href="services/Corporate Services/4">Corporate Services</NavDropdown.Item>
                  <NavDropdown.Item href="services/Accounting Services/5">Accounting Services</NavDropdown.Item>
                  <NavDropdown.Item href="services/Project Finance/6">Project Finance</NavDropdown.Item>

                </NavDropdown>
                <Nav.Link className="link" href="/our-vision">Our Vision</Nav.Link>
                <Nav.Link className="link" href="#contact">Contact Us</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

      </div>

      <a href="https://wa.me/919377092292" target="_blank" className="float">
        <i className="fa fa-whatsapp my-float"></i>
      </a>
    </>
  )
}
